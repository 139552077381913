import { Button, Col, Row } from 'react-bootstrap';
import PageTitle from '../helpers/PageTitle';

const GAPageTitle = ({ hasUrls }) => {
  if (!hasUrls) {
    return <PageTitle title={'Block Scanning Reports'} />;
  }

  const link =
    'https://www.youtube.com/playlist?list=PLW2Of1hBVwfzXQkpnIKrqaVP5APSbyWJE';

  return (
    <Row>
      <Col
        xs={0}
        sm={0}
        md={2}
        className='d-flex justify-content-start pr-0'
        style={{ visibility: 'hidden' }}
      >
        <Button size='sm'>Need Help?</Button>
      </Col>
      <Col className='p-0'>
        <PageTitle title={'Block Scanning Reports'} />
      </Col>
      <Col xs={3} sm={3} md={2} className='d-flex justify-content-end pl-0'>
        <Button size='sm' variant='secondary' href={link} target='_blank'>
          Need Help?
        </Button>
      </Col>
    </Row>
  );
};

export default GAPageTitle;

